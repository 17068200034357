<template>
    <div>
        <AdminTop
            :heading="lessonId ? 'Redigera lektion' : 'Skapa lektion'"
        />

        <BaseForm
            v-if="lesson"
            :loading="loading"
            :submit-label="lessonId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Kurs"
                name="course"
            >
                <SelectField
                    v-if="courses"
                    v-model="lesson.course_id"
                    :items="courses"
                />
            </FormGroup>

            <FormGroup
                label="Sal"
                name="classroom"
            >
                <SelectField
                    v-if="classrooms"
                    v-model="lesson.classroom_id"
                    :items="classrooms"
                />
            </FormGroup>

            <FormGroup
                label="Datum"
                name="date"
            >
                <DatePicker v-model="lesson.date" />
            </FormGroup>

            <FormGroup
                label="Tid"
                name="time"
            >
                <div class="inline-flex items-center form-input">
                    <div>
                        <TimePicker
                            :initial-hour="lesson.startHour"
                            :initial-minute="lesson.startMinute"
                            @changed="(hour, minute) => { lesson.startHour = hour; lesson.startMinute = minute }"
                        />
                    </div>

                    <p class="mx-8 mb-0 text-3xl">
                        Till
                    </p>

                    <div>
                        <TimePicker
                            :initial-hour="lesson.endHour"
                            :initial-minute="lesson.endMinute"
                            @changed="(hour, minute) => { lesson.endHour = hour; lesson.endMinute = minute }"
                        />
                    </div>
                </div>
            </FormGroup>

            <FormGroup
                label="Repeterbar till och med"
                name="repeat_until"
            >
                <DatePicker v-model="lesson.repeat_until" />
            </FormGroup>

            <FormGroup
                label="Lärare"
                name="teacher"
            >
                <MultipleSelectField
                    v-if="teachers"
                    v-model="lesson.teachers"
                    :items="teachers"
                />
            </FormGroup>

            <FormGroup
                label="Elever"
                name="students"
            >
                <MultipleSelectField
                    v-if="users"
                    v-model="lesson.users"
                    :items="users"
                />
            </FormGroup>

            <FormGroup
                label="Information"
                name="information"
            >
                <TextareaField
                    v-model="lesson.information"
                    name="information"
                    class="h-64"
                />
            </FormGroup>

            <FormGroup label="Länkar">
                <Repeater
                    :rows="lesson.links"
                    add-label="Lägg till länk"
                    @removeRow="index => lesson.links.splice(index, 1)"
                    @addRow="addLink"
                >
                    <template v-slot="{ row }">
                        <div
                            class="lg:flex flex-grow grow-shrink-0 -mb-8"
                            style="padding-right: 10rem"
                        >
                            <FormGroup
                                :label-for="`${row.id}_title`"
                                label="Titel"
                                class="w-full"
                            >
                                <InputField
                                    v-model="row.title"
                                    :name="`${row.id}_title`"
                                    type="text"
                                    required
                                />
                            </FormGroup>

                            <FormGroup
                                :label-for="`${row.id}_url`"
                                label="Länk"
                                class="lg:ml-6 w-full"
                            >
                                <InputField
                                    v-model="row.url"
                                    :name="`${row.id}_url`"
                                    type="url"
                                    required
                                />
                            </FormGroup>

                            <FormGroup
                                label="Ikon"
                                class="lg:ml-6 flex-shrink-0"
                            >
                                <IconPicker
                                    v-model="row.icon"
                                    :icons="linkIcons"
                                    small
                                />
                            </FormGroup>
                        </div>
                    </template>
                </Repeater>
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import SelectField from '@/components/form/SelectField';
import DatePicker from '@/components/form/DatePicker';
import InputField from '@/components/form/InputField';
import TextareaField from '@/components/form/TextareaField';
import MultipleSelectField from '@/components/form/MultipleSelectField';
import TimePicker from '@/components/form/TimePicker';
import IconPicker from '@/components/form/IconPicker';
import Repeater from '@/components/form/Repeater';
import LessonService from '@/services/admin/LessonService';
import UserService from '@/services/admin/UserService';
import TeacherService from '@/services/admin/TeacherService';
import CourseService from '@/services/admin/CourseService';
import ClassroomService from '@/services/admin/ClassroomService';
import documentIcon from '@/assets/images/document.svg';
import linkIcon from '@/assets/images/external-link.svg';
import lessonForm from '@/utils/lessonForm';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        SelectField,
        DatePicker,
        InputField,
        TextareaField,
        MultipleSelectField,
        TimePicker,
        IconPicker,
        Repeater
    },

    data: () => ({
        loading: false,
        lesson: null,
        users: null,
        teachers: null,
        courses: null,
        classrooms: null,
        linkIcons: [
            { component: documentIcon, value: 'document' },
            { component: linkIcon, value: 'external-link' }
        ]
    }),

    computed: {
        lessonId () {
            return this.$route.params.id;
        }
    },

    async created () {
        const [lesson, users, teachers, courses, classrooms] = await Promise.all([
            this.getLesson(),
            UserService.students(),
            TeacherService.all(),
            CourseService.all(),
            ClassroomService.all()
        ]);

        this.users = users.data;
        this.teachers = teachers.data;
        this.courses = courses.data;
        this.classrooms = classrooms.data;

        if (!this.lessonId) {
            lesson.users = this.users.map(user => user.id);
        }

        this.lesson = lesson;
    },

    methods: {
        async create () {
            const res = await LessonService.create(this.lesson);

            if (res.status === 200 && res?.data?.lesson?.id) {
                this.$router.push(`/admin/events/lessons/${res.data.lesson.id}`);
            }
        },

        async update () {
            return await LessonService.update(this.lessonId, this.lesson);
        },

        ...lessonForm
    }
};
</script>
